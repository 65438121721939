import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useLocation } from '@reach/router'
// import useStaticModule from '@hooks/useStaticModule'
import TracklineBackground from '@objects/tracklineBackground'
import FaqList from '@components/faqlist/index'
import Container from '@objects/container'
import Headline from '@objects/headline'
import GlossaryTabs from '@objects/glossaryTabs/index'

function Glossar({ data, pageContext }) {
  const intl = useIntl()
  const location = useLocation()
  // const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const glossaryList = data.allContentfulGlossaryEntry.nodes
    .filter((entry) => entry.synonyms)
    .map((entry) => {
      return {
        id: entry.synonyms[0].replace(/\s+/g, ''),
        question: entry.title,
        answer: entry.description,
        glossaryExcludes: entry.synonyms,
      }
    })

  const letters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const glossarySections = letters.match(/[a-zA-Z0-9]/gi).map((letter) => {
    return {
      letter: letter.toUpperCase(),
      id: letter.toUpperCase(),
      title: letter.toUpperCase(),
      faqs: glossaryList.filter((entry) =>
        entry.question.toUpperCase().startsWith(letter.toUpperCase())
      ),
    }
  })

  const [active, setActive] = useState(
    glossarySections.findIndex((s) => s.faqs.length > 0)
  )

  // open tab from url hash
  useEffect(() => {
    const query = queryString.parse(location.hash).glossaryitem
    if (query) {
      const index = glossarySections.findIndex(
        (s) => s.letter.toLowerCase() === query.charAt(0).toLowerCase()
      )
      if (index && glossarySections[index].faqs.length > 0) {
        setActive(index)
      }
    }
  }, [location.hash])

  function renderPanels() {
    return glossarySections.map((section, i) => {
      return <FaqList key={i} type="glossary" data={[section]} />
    })
  }

  function changeTab() {
    navigate(location.pathname, {
      replace: true,
      state: {
        preventScroll: true,
      },
    })
  }

  return (
    <Container role="region" aria-label="Glossar">
      <TracklineBackground track={1} bottom={'10%'} />
      <TracklineBackground track={4} right width={400} top={'0%'} />

      <Headline
        level={1}
        className="mb-6  text-3xl md:text-5xl lg:w-10/12"
        ariaLabel={intl.formatMessage({ id: 'glossary.headline' })}
      >
        {intl.formatMessage({ id: 'glossary.headline' })}
      </Headline>

      <GlossaryTabs
        tabHeads={glossarySections
          .filter((section) => /^[A-Z0-9]/.test(section.letter))
          .map((section) => {
            return {
              label: section.letter,
              disabled: section.faqs.length > 0 ? false : true,
              onClick: changeTab,
            }
          })}
        type="simple"
        active={active}
      >
        {renderPanels()}
      </GlossaryTabs>
    </Container>
  )
}

Glossar.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Glossar

export const pageQuery = graphql`
  query GlossaryPageQuery($locale: String!) {
    allContentfulGlossaryEntry(filter: { node_locale: { eq: $locale } }) {
      nodes {
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        synonyms
      }
    }
  }
`
